@import './fonts';

.tt-sticky-top {
  @extend .sticky-top;
  z-index: 0;
  top: $calculated-navbar-height-sm;
  @include media-breakpoint-up(md) {
    top: $calculated-navbar-height + 2rem;
  }
}

// body {
//   & > * + * {
//     margin-top: 16px;
//   }
// }

// NEW PRIMARY BTN

// button-variant(background, border-color, hover:background-color, hover:border-color, active:background-color, active:border-color)

.btn {
  font-size: 1rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.btn-primary {
  @include button-variant($red, $red, darken($red, 10%), darken($red, 10%), lighten($red, 10%), lighten($red, 10%));
}

.btn-primary.btn-lg,
.btn-lg {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1.2px;
  //line-height: 100%;
  //padding: 18px 22px 11px;
  text-transform: uppercase;
}

.btn,
.nav-item {
  text-transform: uppercase;
}

.shoppingcart-finisher {
  background-color: rgba($tvog-black3, 0.8);
}

.sticky-bottom.is-valid.when-valid .btn-primary,
.shoppingcart-finisher .btn-primary {
  transition: all 300ms ease;
  &:hover {
    letter-spacing: 3px;
  }
}

.media-heading {
  font-family: $font-family-sans-serif;
}

body.product-listing-page {
  #main {
    background-color: $tvog-cream;
    //color: hsla(0, 0%, 100%, 0.6);
  }
  #product-listing {
    min-height: 50vh;
  }
}

body.product-detail-page {
  #main {
    background-color: $tvog-cream;
    p {
      //color: hsla(0, 0%, 100%, 0.6);
    }
    // h1,
    // h2,
    // h3,
    // strong {
    //   color: $white;
    // }
  }
  .container.detail-page {
    //color: hsla(0, 0%, 100%, 0.6);
  }
  .carousel-inner {
    box-shadow: $box-shadow-dark;
    //border-radius: 1rem;
    background-color: lighten($tvog-cream, 5%);
  }
  .carousel-item-content img {
    //border-radius: 1rem;
  }
  .carousel-control-prev,
  .carousel-control-next {
    color: $tvog-red;
  }
  .carousel-indicators {
    @include media-breakpoint-down(md) {
      display: none;
    }
    position: relative;
    bottom: -10px;
  }
  .product-thumb {
    background-color: transparent !important;
    //border-radius: $border-radius;
    height: 60px;
    width: 60px;
    margin: 5px;
  }
  header {
    // @include media-breakpoint-down(md) {
    //   margin-top: 80px !important;
    // }
  }
  .variant-chooser .variant-chooser-quantity input {
    @extend .mx-2;
  }
  .variant-chooser .variant-chooser-price {
    //color: $white;
  }
}

.product-content {
  background: transparent;
  a {
    //color: $white;
  }
  a:hover {
    //color: rgba($white, 30%);
  }
}

#root {
  padding-top: 0;
}

#prefooter {
  background: $tvog-black3;
  font-size: $font-size-sm;
  //min-height: 40vh;
  color: #fff;
  line-height: 1.75rem;
  h5 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: $h3-font-size;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  .copyright {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
    }
  }
}

#footer {
  background: $tvog-black;
  font-size: $font-size-sm;
  min-height: 40vh;
  color: #fff;
  line-height: 1.75rem;
  h5 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: $h3-font-size;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
      margin-right: 10px;
    }
    margin-top: 25px;
  }
}

.headerimage {
  background: $body-bg url('./assets/images/header.jpg') no-repeat center center;
  background-size: cover;
  margin-top: 0;
  height: 35vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-shadow: $box-shadow-xl;
  @include media-breakpoint-up(md) {
    height: 50vh;
  }
  @include media-breakpoint-up(lg) {
    height: 60vh;
  }
  .headerimage-content {
    width: 75vw;
    @include media-breakpoint-down(sm) {
      width: 100vw;
      padding: 20px !important;
    }
    h1 {
      color: $white;
      @include media-breakpoint-down(sm) {
        font-size: $h4-font-size;
      }
    }
  }
}

body[data-scrolled] {
  .headerimage {
    opacity: 0.5;
    transition: all 0.2s ease;
    //transform: translateY(0);
  }
}

// .masonry .maki-container {
//   align-items: flex-start;
// }

// .maki.card .card-body {
//   padding: $card-spacer-x;

//   .card-title {
//     @extend .text-truncate;
//     .title {
//       @extend .text-truncate;
//       font-weight: 900;
//     }
//   }
// }

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  //font-weight: 400 !important;
  //text-transform: uppercase;
}

// .nav-item {
//   // @include media-breakpoint-up (md) {
//   //   font-size: 25px;
//   // }
//   transition: all 300ms ease;
//   letter-spacing: 0px;
//   &:hover {
//     letter-spacing: 3px;
//   }
// }

#main-menu {
  background: transparent;
  .nav-link {
    background-color: $black; //$light-milk;
    color: $white;
    margin-bottom: 0.4rem;
    font-weight: 900;
    text-transform: uppercase;
    &:hover,
    &.active {
      border-radius: 0 !important;
      background-color: $tvog-red;
    }
    &.primary {
      color: $tvog-red !important;
    }
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

#header > .navbar {
  background-color: $tvog-black;
  color: #fff;
  height: $calculated-navbar-height-sm;
  // font-size: 16px;
  box-shadow: $box-shadow-lg;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height-sm + 1rem;
    padding-left: 2vw;
    padding-right: 2vw;
  }
  .nav-item {
    @extend .ml-3;
    &.last {
      @extend .ml-1;
    }
  }
  i.fa-user {
    font-weight: 300 !important;
  }
  .nav-link {
    font-weight: bold;
    letter-spacing: 1.2px;
    &:hover,
    &:focus,
    &:active {
      //border-radius: $btn-border-radius;
      background-color: $black;
    }
    &.active {
      //border-radius: $btn-border-radius;
    }
    &.primary {
      color: $tvog-red;
    }
  }
  .navbar-brand {
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    img {
      height: 1.25rem;
      width: auto;
      max-height: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      img {
        height: 2rem;
        width: auto;
        max-height: 2rem;
      }
    }
  }
  .hamburger-inner:after,
  .hamburger-inner:before,
  .hamburger-inner {
    height: 1px;
    width: 25px;
  }
}

.main-menu .nav-link.hamburger {
  background-color: transparent !important;
}

.offcanvas-collapse {
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

video {
  background: $dark;
}

#cart-menu,
#main-menu {
  font-size: 16px;
}
#main-menu {
  bottom: auto;
  overflow: visible;
}

.logo-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
  pointer-events: none;
}

// .logo-name {
//   // font-size: 18px;
//   @include media-breakpoint-up(md) {
//     font-size: 40px;
//   }
//   font-weight: 900;
//   font-style: normal;
// }
// .logo-branch {
//   @extend .d-none;
//   @extend .d-md-inline;
//   font-size: 30px;
//   font-weight: 500;
//   font-style: normal;
//   color: rgba(0, 0, 0, 0.5);
//   position: relative;
//   padding-left: 30px;

//   &::before {
//     position: absolute;
//     content: '';
//     left: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 3px;
//     height: 35px;
//     background-color: currentColor;
//   }
// }

.product-listing {
  background: $info;
}

// MAKIS IN DA HOOD

.maki {
  //padding: 1rem;
  text-align: center;
  .title {
    font-family: $headings-font-family;
    text-transform: uppercase;
  }
  .card-body {
    //padding-bottom: 0px;
  }
  &:hover {
    transform: rotate(-1.5deg);
  }
}

.product-detail-title {
  //font-weight: 400;
  font-style: normal;
  //font-size: 2rem;
  @include media-breakpoint-up(md) {
    //font-size: 55px;
  }
  position: relative;
  margin-top: 0;
  margin-bottom: map-get($spacers, 4);

  small  {
    font-family: $font-family-sans-serif;
    text-transform: none;
  }

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   left: 50%;
  //   bottom: map-get($spacers, 2) * -1;
  //   width: 85px;
  //   height: 3px;
  //   background-color: $black;
  //   transform: translateX(-50%);

  //   @include media-breakpoint-up(md) {
  //     left: 0;
  //     width: 200px;
  //     transform: translateX(0);
  //   }
  // }
}

// MAKI 2020 OVERWRITES

.maki-2020 {
  box-shadow: $box-shadow-dark;
  &,
  &:link,
  &:hover,
  &:focus,
  &:active {
    color: $card-color;
    text-decoration: none;
  }
  flex: 1;
  //box-shadow: none;
  //border-radius: 1rem;
  overflow: hidden;
  transition: all 0.4s ease;
  border: 0;

  .maki-img,
  .maki-img-hover {
    transition: opacity 0.5s;
  }

  .maki-img-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: none;
  }

  &:hover {
    // .maki-img {
    //   opacity: 0.75;
    // }
    box-shadow: $box-shadow-xl-dark;
    transform: scale(1.02) translateZ(0) perspective(1px);
    img {
      //filter: brightness(1.05);
    }
    .maki-img-hover {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: $badge-padding-y $badge-padding-x;
    //background: fade-out(red, 0.9);
    color: $white;
    opacity: 1;
    transition: all 0.2s;
    // @extend .small;
  }

  .card-body {
    //background: linear-gradient(180deg, rgba($tvog-black3, 0.0), rgba($tvog-black3, 1.0));
    background: linear-gradient(180deg, rgba($tvog-black3, 0), rgba($tvog-black3, 0.8), rgba($tvog-black, 0.5));

    backdrop-filter: none;

    .card-title {
      color: $white;
      span.title {
        font-size: $h3-font-size;
      }
    }

    text-align: center;
    position: relative;
    transition: box-shadow 0.2s ease;

    .overlay {
      top: auto;
      bottom: 100%;
    }

    .sub-title {
      //display: none;
      @extend .text-muted;
    }

    span.price {
      display: block;
      margin-top: 0.25rem;
      font-weight: bold;
    }

    small  {
      font-family: $font-family-sans-serif;
      text-transform: none;
    }
  }

  .card-img-overlay {
    top: auto;
    padding: 0;
    // max-height: 100%;
    // overflow-y: auto;
  }

  // .active-menu {
  // }

  &:hover,
  &:focus,
  &:active {
    box-shadow: $box-shadow-lg-dark;
    .overlay {
      opacity: 0.7;
    }
    .card-body {
      //box-shadow: $box-shadow-inverse-xl;
      box-shadow: none;
      background: linear-gradient(180deg, rgba($tvog-black3, 0), rgba($tvog-black3, 0.8), rgba($tvog-black3, 0.5));
      .card-title {
        color: $white;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

.cart-listing {
  background-color: $white;
}
